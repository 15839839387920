export const intoText01 = (
  <p>
    Our business model is premised on how <span className="efficient-effective-text">efficient and effective</span> we are in setting you up for success.
  </p>
);
export const intoText02 = (
  <>
    We offer you the same strategy, perseverance, and adaptability we use to chart our course.
  </>
);
