import React from "react";
import { ConfigProvider, Layout } from "antd";

import { TheHeader, TheFooter } from "containers";
import { Parallax } from "react-parallax";
import colors from "app/Config.scss";
import { 
  Landing,
  IntroAndServices
} from "./sections";

import "./ServicePage.scss";

function ServicePage({ content }) {
  const backgroundImage = content.background;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextHeading: colors.colorBrand_Ivory,
          colorText: colors.colorBrand_Blue,
        },
      }}
    >
      <Parallax bgImage={backgroundImage} strength={500}>
        <Layout.Content className="content-container">
          <TheHeader />
          <Landing content={content.landingContent} />
          <IntroAndServices content={content.introAndServicesContent}/>
          
          <TheFooter />
        </Layout.Content>
      </Parallax>
    </ConfigProvider>
  );
}

export default ServicePage;
