import colors from "../../app/Config.scss";

export const configProvider_Home = {
  token: {
    colorPrimary: colors.colorBrand,
  },
};
export const configProviderIP = {
  token: {
    colorTextHeading: colors.colorBrand_Ivory,
    colorText: colors.colorBrand_Ivory,
  },
};

export const configProviderCorpCommercial = {
  token: {
    colorTextHeading: colors.colorBrand_Red,
  },
};
export const configProviderConvey = {
  token: {
    colorTextHeading: colors.colorBrand_Pink,
  },
};
