import React from "react";
import { ServicePage } from "shared/components";
import backgroundImage from "assets/Images/Services/intellectual-property.jpg";
import * as content from "./IntellectualPropertyContent";
import introAndServicesContent from "./IntroAndServicesContent";

function IntellectualProperty() {
  const pageContent = {
    background: backgroundImage,
    landingContent: {
      title: content.intellectualTitle,
      text: content.intellectualText01,
    },
    introAndServicesContent: introAndServicesContent
  };
  return <ServicePage content={pageContent} />;
}

export default IntellectualProperty;
