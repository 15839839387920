import React from 'react';
function LogoAfrica({ fill = "#6a5acd",outline = "black"}) {
    return (
      <g className='logo-africa'>
        <line
          className="cls-3"
          style={{ stroke: outline }}
          x1="171.62"
          y1="301.75"
          x2="256.88"
          y2="214.17"
        />
        <polyline
          className="cls-7"
          style={{ fill: fill, stroke: outline }}
          points="426.82 220.67 257.89 216.12 267.59 276.68"
        />
        <line
          className="cls-3"
          style={{ stroke: outline }}
          x1="226.54"
          y1="375.26"
          x2="331.9"
          y2="382.67"
        />
        <line
          className="cls-3"
          style={{ stroke: outline }}
          x1="396.87"
          y1="532.2"
          x2="326.06"
          y2="400.49"
        />
        <line
          className="cls-3"
          style={{ stroke: outline }}
          x1="397.18"
          y1="537.81"
          x2="428.82"
          y2="467.19"
        />
        <line
          className="cls-3"
          style={{ stroke: outline }}
          x1="421.31"
          y1="220.67"
          x2="446.5"
          y2="306.5"
        />
        <line
          className="cls-3"
          style={{ stroke: outline }}
          x1="464.56"
          y1="388.58"
          x2="505.44"
          y2="299.97"
        />
        <line
          className="cls-3"
          style={{ stroke: outline }}
          x1="427.47"
          y1="471.03"
          x2="464.62"
          y2="388.58"
        />
        <polyline
          className="cls-7"
          style={{ fill: fill, stroke: outline }}
          points="230.75 376.86 178.98 303.55 265.51 292.32"
        />
        <line
          className="cls-6"
          style={{ stroke: outline }}
          x1="329.82"
          y1="382.32"
          x2="326.5"
          y2="404.5"
        />
        <polyline
          className="cls-7"
          style={{ fill: fill, stroke: outline }}
          points="508.35 297.06 440.29 312.32 489.71 326.89"
        />
        <polyline
          className="cls-11"
          style={{ fill: fill }}
          points="349.53 431.96 426.45 435.68 386.38 501.54"
        />
        <polyline
          className="cls-7"
          style={{ fill: fill, stroke: outline }}
          points="459.94 396.57 373.5 408.5 453.37 419.05"
        />
        <polyline
          className="cls-7"
          style={{ fill: fill, stroke: outline }}
          points="272.42 296.22 331.73 377.71 426.05 246.33"
        />
        <polyline
          className="cls-11"
          style={{ fill: fill }}
          points="359 386.83 413.84 309.5 467.92 367.39"
        />
      </g>
    );
  }

export default LogoAfrica;
