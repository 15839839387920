import React from "react";
import "./Landing.scss";
import { LandingTitle } from "shared/components";

function Landing({ content }) {
  return (
    <section className="landing">
      <div className="corporate-and-commercial-title">
        <LandingTitle
          title={content.title}
          text={
            <>
              {content.text}
              <div className="mini-line"></div>
            </>
          }
        />
      </div>
    </section>
  );
}

export default Landing;
