import { Carousel } from 'antd';
function ImageTextCarousel({ twoColumnContainers }) {
    return (
      <Carousel >
        {twoColumnContainers.map((container, index) => (
          <div key={index} >
            {container}
          </div>
        ))}
      </Carousel>
    );
  }
  
export default ImageTextCarousel;

