import { AlAfrica } from "../../../../../shared/components";
import colors from "../../../../../app/Config.scss";
export const professionalismTitle = "Professionalism";
export const professionalismText01 = (
  <>
    Our team is committed to providing competent, effective service to clients
    with impeccable respect for the client and the judicial system and process.
  </>
);

export const integrityTitle = "Integrity";
export const integrityText01 = (
  <>
    We service your needs without compromising on our integrity or
    professionalism, by providing honest and fair advice. We are realistic and
    truthful about our service and expertise because we are motivated by our
    desire to be a trusted formidable brand.
  </>
);

export const knowledgeTitle = "Knowledge";
export const knowledgeText01 = (
  <>
    We aspire to be a world-class law firm. Our team regularly equips and
    attends training on the services we offer. Our team keeps updated so that
    our clients can receive firsthand information here.
  </>
);

export const listenTitle = "Listen";
export const listenText01 = (
  <>
    Because we appreciate that talking is an occupational hazard, we
    intentionally listen well. We also recognize that listening is a skill that
    helps us understand the complexities of you matter so we can best serve your
    legal needs.
  </>
);

export const adaptabilityTitle = "Adaptability";
export const adaptabilityText01 = (
  <>
    Our team recognises that adaptability is essential to businesses thriving in
    an evolving society where technology constantly reshapes societal norms.
  </>
);

export const titleTextId = [
  {
    id: professionalismTitle,
    logo: <AlAfrica fillTriangles={colors.colorBrand_Red} />,
    title: professionalismTitle,
    text: professionalismText01,
  },

  {
    id: integrityTitle,
    logo: <AlAfrica fillTriangles={colors.colorBrand_Gold} />,
    title: integrityTitle,
    text: integrityText01,
  },
  {
    id: adaptabilityTitle,
    logo: <AlAfrica fillTriangles={colors.colorBrand_Blue} />,
    title: adaptabilityTitle,
    text: adaptabilityText01,
  },
  {
    id: knowledgeTitle,
    logo: <AlAfrica fillTriangles={colors.colorBrand_Pink} />,
    title: knowledgeTitle,
    text: knowledgeText01,
  },
  {
    id: listenTitle,
    logo: <AlAfrica fillTriangles={colors.colorBrand_Green} />,
    title: listenTitle,
    text: listenText01,
  },
];
