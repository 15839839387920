import React from "react";
import { TwoColumnContainer } from "shared/components";
import {ImageTextCarousel} from "shared/components";
import {Typography} from "antd";
import color from "app/Config.scss";

import AlSymbol from "shared/components/AlLogo/AlSymbol";
import alayna from "assets/Images/Home/alayna-linkedin.jpg";
import content from "./AboutUsContent";

import "./AboutUs.scss";

function AboutUs() {
  const { Title } = Typography;
  const fill = {
      top: color.colorBrand_Blue,
      upper_middle: color.colorBrand_Gold,
      middle: color.colorBrand_Red,
      lower_middle: color.colorBrand_Green,
      bottom: color.colorBrand_Pink
  };
  const twoColumnContainers = [
    <TwoColumnContainer
      contentLeft={<div className="columnImage"><img className="img-owner" src={alayna} alt=""/></div>}
      contentRight={content[0]}
    />,
    <TwoColumnContainer
      contentLeft={<AlSymbol fill={fill}/>}
      contentRight={content[1]}
    />
    
  ];

  return (
    <section className="about-us-section">
        <div className="about-us-title">
          <Title style={{ color: "" }}>Who We Are</Title>
        </div>
      <div className="about-us">
        <ImageTextCarousel twoColumnContainers={twoColumnContainers} />
      </div>
    </section>
  );
  
}

export default AboutUs;
