import React from "react";

function AlLogoText({ outline }) {
  return (
    <g className="logo-text">
      <text className="al-text" style={{ stroke: outline }} transform="translate(500.44 410.64) scale(1.43 1)">
        <tspan x="0" y="0">AL</tspan>
      </text>
      <text className="inc-text" style={{ stroke: outline }} transform="translate(776.42 418.11) scale(1.62 1)">
        <tspan x="0" y="0">inc.</tspan>
      </text>
      <line className="divider-line" style={{ stroke: outline }} x1="483.52" y1="434.49" x2="1080.16" y2="434.49" />
      <text className="company-description" style={{ stroke: outline }} transform="translate(486.92 476.06) scale(.94 1)">
        <tspan x="0" y="0">Attorneys | Conveyancers | Consultants</tspan>
      </text>
    </g>
  
  );
}

export default AlLogoText;
