import React from "react";
import { ConfigProvider } from "antd";
import {
  AboutUs,
  OurAreas,
  TheLandingTitle,
  Values,
} from "./components";
import { TheHeader, TheFooter } from "containers";
import { Parallax } from 'react-parallax';
import colors from "app/Config.scss";

import "./Home.scss";

function Home() {
  const backgroundImage = require("assets/Images/Home/landing_background.jpg");
  /*

  const svgData = [
    { color: colors.colorBrand, size: 100 },
    { color: colors.colorBrand_Blue, size: 120 },
    { color: colors.colorBrand_Gold, size: 80 },
    { color: colors.colorBrand_Ivory, size: 110 },
    { color: colors.colorBrand_Green, size: 90 },
    { color: colors.colorBrand_Pink, size: 130 },
    { color: colors.colorBrand_Blue, size: 70 },
    { color: colors.colorBrand_Gold, size: 100 },
    { color: colors.colorBrand_Ivory, size: 110 },
  ];

{svgData.map((item, index) => {
            const randomRotation = Math.random() * 360;
            const randomTranslateX = Math.random() * 200 - 100;
            const randomTranslateY = Math.random() * 200 - 100;

            return (
              <div
                className="svg-item"
                key={index}
                style={{
                  backgroundColor: item.color,
                  width: `${item.size}px`,
                  height: `${item.size}px`,
                  "--random-rotation": `${randomRotation}deg`,
                  "--random-translate-x": `${randomTranslateX}%`,
                  "--random-translate-y": `${randomTranslateY}%`,
                }}
              ></div>
            );
          })}*/

  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextHeading: colors.colorBrand_Ivory,
          colorText: colors.colorBrand_Blue,
        },
      }}
    >
      <Parallax className="parallax-container" bgImage={backgroundImage} strength={1000}>
        <div className="content-container">
          <TheHeader />
          <TheLandingTitle />
          <AboutUs />
          <Values />
          <OurAreas />
          <TheFooter />

          
        </div>
      </Parallax>
    </ConfigProvider>
  );
}

export default Home;