import React from "react";
import { ServicePage } from "shared/components";
import backgroundImage from "assets/Images/Services/debt-collection.jpg";
import * as content from "./DebtCollectionContent";
import introAndServicesContent from "./IntroAndServicesContent";

function DebtCollection() {
  const pageContent = {
    background: backgroundImage,
    landingContent: {
      title: content.debtTitle,
      text: content.debtIntroText01,
    },
    introAndServicesContent: introAndServicesContent,
  };
  
  return <ServicePage content={pageContent} />;
}

export default DebtCollection;
