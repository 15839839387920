import React from "react";
import { TwoColumnContainer, ImageTextCarousel } from "shared/components";
import color from "app/Config.scss";

import AlSymbol from "shared/components/AlLogo/AlSymbol";
import alayna from "assets/Images/Home/alayna-linkedin.jpg";

import "./IntroAndServices.scss";

function IntroAndServices({content}) {
    
  const fill = {
    top: color.colorBrand_Blue,
    upper_middle: color.colorBrand_Gold,
    middle: color.colorBrand_Red,
    lower_middle: color.colorBrand_Green,
    bottom: color.colorBrand_Pink,
  };
  const twoColumnContainers = [
    <TwoColumnContainer
      contentLeft={<div className="columnImage"><img className="img-owner" src={alayna} alt="" /></div>}
      contentRight={content[0]}
    />,
    <TwoColumnContainer
      contentLeft={<AlSymbol fill={fill} />}
      contentRight={content[1]}
    />,
  ];

  return (
    <section className="intro-and-services-section">
      <div className="intro-and-services">
        <ImageTextCarousel twoColumnContainers={twoColumnContainers} />
      </div>
    </section>
  );
}

export default IntroAndServices;
