import React from "react";
import { ServicePage } from "shared/components";
import backgroundImage from "assets/Images/Services/labour.jpg";
import * as content from "./LabourContent";
import introAndServicesContent from "./IntroAndServicesContent";

function Labour() {
  const pageContent = {
    background: backgroundImage,
    landingContent: {
      title: content.labourTitle,
      text: content.labourText01,
    },
    introAndServicesContent: introAndServicesContent
  };
  return <ServicePage content={pageContent} />;
}

export default Labour;
