export const whoWeAreTitle = <p> Who we are</p>;
export const whoWeAreText01 = (
  <p>
    AL Inc. is a wholly black-owned Corporate Commercial Law firm headquartered
    in the Western Cape. Though the team at AL Inc. has more than 10 years of
    experience among them, they launched in March 2020, weeks prior to the
    lockdown. Given the economic strain that the COVID-19 pandemic placed on
    organisations and individuals nationwide, it is commendable that AL Inc not
    only survived but thrived during 2020/21.
  </p>
);
export const whoWeAreText02 = (
  <>
    <span className="quotes">“</span>Our team is trained to think smart and
    efficiently while offering you world-class service. We aim to take care of
    your legal needs to help you grow and succeed as quickly as reasonably
    possible. It is important that you feel safe, protected, and cared for.
    <span className="quotes">”</span>
  </>
);
export const alaynaName = <> - Alayna Lewendal</>;
export const alaynaMd = <>(Managing Director)</>;

const content = [
  {
    title: "Who we are",
    text: (
      <>
        <span className="quotes">“</span>Our team is trained to think intelligently and work efficiently to provide you with world-class service. We are dedicated to addressing your legal needs to support your growth and success in a timely manner.
        <span className="quotes">”</span>
      </>
    )
  },
  {
    title: "Who we are",
    text: (
      <>
          AL Inc. is a wholly black-owned Corporate Commercial Law firm headquartered
          in the Western Cape. Though the team at AL Inc. has more than 10 years of
          experience among them, they launched in March 2020, weeks prior to the
          lockdown. Given the economic strain that the COVID-19 pandemic placed on
          organisations and individuals nationwide, it is commendable that AL Inc not
          only survived but thrived during 2020/21.
      </>
    )
  }
];

export default content;
