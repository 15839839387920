import React from "react";
import { Typography } from "antd";

import "./LandingTitle.scss";
function LandingTitle({ styleText, styleTitle, text, title }) {
  const { Title } = Typography;

  return (
    <div className="landing-title">
      <div className="wrapper">
        <div className="text">
          <Title className="title" style={styleTitle}>
            {title}
          </Title>
          <Title className="subtitle" style={styleText}>
            {text}
          </Title>
        </div>
      </div>
    </div>
  );
}

export default LandingTitle;
