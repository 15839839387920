import React from "react";
import { Layout } from "antd";
import { LinkedinFilled, MailFilled } from "@ant-design/icons";

import "./TheFooter.scss";
import { AlLogo } from "../../shared/components";

function TheFooter() {
  //TO-DO: Add contact form and quick links
  const iconStyle = { fontSize: "36px", color: "white" };
  return (
    <Layout.Footer>
      <div className="footer-content">
        <AlLogo />
        <div className="social-media-links">
          <a
            href="https://www.linkedin.com/in/alayna-lewendal-65aa5a13b/"
          >
            <LinkedinFilled style={iconStyle} />
          </a>
          <a href="mailto:solutions@lewendallaw.co.za">
            <MailFilled style={iconStyle} />
          </a>
        </div>
      </div>
      <div className="copyright">© Lewendal Law 2024. All rights reserved.</div>
    </Layout.Footer>
  );
}

export default TheFooter;
