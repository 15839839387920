export const teamMembers = [
  {
    name: "Alayna Lewendal",
    jobTitle: "Managing Director",
    email: "alayna@lewendallaw.co.za",
    phoneNumber: "12345678",
    photo: "alayna.jpg",
    details: ""
  },
  {
    name: "Robin Van Reinsburg",
    jobTitle: "Associate",
    email: "robin@lewendallaw.co.za",
    phoneNumber: "078 153 0964",
    photo: "alayna.jpg",
    details: ""
  },
  {
    name: "Eleanor Sonnenberg",
    jobTitle: "Candidate Attorney",
    email: "eleanor@lewendallaw.co.za",
    phoneNumber: "081 727 8727",
    photo: "alayna.jpg",
    details: ""
  },
  {
    name: "Caroline Wilson",
    jobTitle: "Consultant",
    email: "caroline@lewendallaw.co.za",
    phoneNumber: "084 221 2222",
    photo: "alayna.jpg",
    details: ""
  },
  {
    name: "Joyce Smart",
    jobTitle: "Consultant",
    email: "joyce@lewendallaw.co.za",
    phoneNumber: "072 606 3972",
    photo: "alayna.jpg",
    details: ""
  },
  {
    name: "Meche Stewart",
    jobTitle: "Candidate Attorney",
    email: "meche@lewendallaw.co.za",
    phoneNumber: "",
    photo: "alayna.jpg",
    details: ""
  },
  {
    name: "Rozan Newfeldt",
    jobTitle: "Consultant",
    email: "rozan@lewendallaw.co.za",
    phoneNumber: "",
    photo: "alayna.jpg",
    details: ""
  }
];
