import React from "react";
import { ConfigProvider, Layout } from "antd";
import {TheHeader, TheFooter} from "containers";
import { Parallax } from 'react-parallax';
import colors from "app/Config.scss";

import "./Team.scss";
import TeamGrid from "./TeamGrid";

function Team() {
  const backgroundImage = require("assets/Images/Services/team.jpg");

  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextHeading: colors.colorBrand_Ivory,
          colorText: colors.colorBrand_Blue,
        },
      }}
    >
      <Parallax bgImage={backgroundImage} strength={1000}>
        <Layout.Content className="content-container">
          <TheHeader />
          <div className="team-container">
            <TeamGrid />
          </div>
          <TheFooter />
        </Layout.Content>
      </Parallax>
    </ConfigProvider>
  );
}

export default Team;
