import React from "react";
import { Carousel } from "antd";
import * as content from "./ClientReviewsContent";

import "./ClientReviews.scss";

function ClientReviews() {
  /*const contentStyle = {
    height: "160px",
    width: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "pink",
  };*/
  return (
    <section className="client-review">
      <div className="client-review-carousel">
        <div className="carousel-header">{content.carouselHeader}</div>
        <Carousel autoplay>
          {content.reviewsList.map((review) => (
            <h3 className="carousel-text-area">{review.text}</h3>
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default ClientReviews;
