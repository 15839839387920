import React from "react";
import { Typography } from "antd";
import { MailOutlined, PhoneFilled } from "@ant-design/icons";
import { TwoColumnContainer } from "../../shared/components";
import "./ContactUs.scss";

function ContactUs() {
  const { Title } = Typography;
  const iconStyles = {
    fontSize: "48px",
    color: "white",
  };
  return (
    <>
      <div className="contact-us-wrapper">
        <Title level={2}>Contact us</Title>
        <TwoColumnContainer
          contentLeft={
            <>
              <MailOutlined style={iconStyles} />
              <Title level={5}>EMAIL</Title>
              <Title level={5}>
                <a href="mailto:alayna@lewendallaw.co.za">
                  alayna@lewendallaw.co.za
                </a>
                <br />
                <a href="mailto:solutions@lewendallaw.co.za">
                  solutions@lewendallaw.co.za
                </a>
              </Title>
            </>
          }
          contentRight={
            <>
              <PhoneFilled style={iconStyles} />
              <Title level={5}>PHONE</Title>
              <Title level={5}>
                <a href="tel:+27 21 565 0473">021 565 0473</a>
              </Title>
            </>
          }
        />
      </div>
    </>
  );
}

export default ContactUs;
