import React from "react";
import { ServicePage } from "shared/components";
import backgroundImage from "assets/Images/Services/estate-planning.jpg";
import * as content from "./EstatePlanningContent";
import introAndServicesContent from "./IntroAndServicesContent";

function EstatePlanning() {
  const pageContent = {
    background: backgroundImage,
    landingContent: {
      title: content.estatePlanningTitle,
      text: content.estatePlanningText03,
    },
    introAndServicesContent: introAndServicesContent,
  };
  
  return <ServicePage content={pageContent} />;
}

export default EstatePlanning;
