import React from 'react';

import LogoOuterPolygon from './LogoOuterPolygon';
import LogoAfrica from './LogoAfrica';
function LogoPolygon({ fill = { default: '#6a5acd' } }) {
  
  const defaultFillColor = fill.default;
  const middleFillColor = fill.middle || defaultFillColor;
  const topFillColor = fill.top || defaultFillColor;
  const lowerMiddleFillColor = fill.lower_middle || defaultFillColor;
  const bottomFillColor = fill.bottom || defaultFillColor;
  const upperMiddleFillColor = fill.upper_middle || defaultFillColor;
  
    return (
      <g className='logo-polygon'>
        <g className='logo-outer-polygon'>
          <LogoOuterPolygon
          className="logo-outer-polygon logo-outer-polygon-middle"
          fill={middleFillColor}
          points="100.29 520.17 202.84 460.44 48.6 399.04 174.97 293.76 58.4 269.65 0 411 100.29 520.17"
          />
          <LogoOuterPolygon
          className="logo-outer-polygon logo-outer-polygon-top"
          fill={topFillColor}
          points="442.81 88.17 406.57 201.56 315.17 64.82 238.33 212.16 187.13 102.81 307 0 442.81 88.17"
          />
          <LogoOuterPolygon
          className="logo-outer-polygon logo-outer-polygon-bottom"
          fill={lowerMiddleFillColor}
          points="514.76 576.32 449.37 477.28 396.71 634.72 284.51 514.46 266.98 632.2 406.57 678.73 514.76 576.32"
          />
          <LogoOuterPolygon
          className="logo-outer-polygon logo-outer-polygon-lower-middle"
          fill={bottomFillColor}
          points="238.11 616.77 262.71 515.32 166.58 589.19 215.18 479.67 124.63 538.41 143.86 616.77 238.11 616.77"
          />
          <LogoOuterPolygon
          className="logo-outer-polygon logo-outer-polygon-upper-middle"
          fill={upperMiddleFillColor}
          points="74.68 235.45 174.14 267.12 107.01 166.22 213.04 222.21 160.81 127.76 74.68 140.02 74.68 235.45"
          />
        </g>
        <LogoAfrica fill={defaultFillColor}/>
      </g>
    );
  }

export default LogoPolygon;
