import { Row, Col } from "antd";
import TeamMember from "./TeamMember";
import { teamMembers } from "./TeamMembers";

const TeamGrid = () => {
  return (
    <Row className="team-grid" gutter={[16, 16]}>
      {teamMembers.map((member, index) => (
        <Col key={index} xs={24} sm={12} md={8} lg={6}>
          <TeamMember content={member} />
        </Col>
      ))}
    </Row>
  );
};

export default TeamGrid;
