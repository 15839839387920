import React from "react";

import * as content from "./TheLandingContent";

import { LandingTitle } from "../../../../../shared/components";
import "./TheLandingTitle.scss";

function TheLandingTitle() {
  return (
    <section className="the-landing">
      
      <LandingTitle
        title={content.intoText01}
        text={
          <>
            {content.intoText02}
            <div className="mini-line"></div>
          </>
        }
      />
    </section>
  );
}

export default TheLandingTitle;