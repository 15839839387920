export const debtTitle = "Debt Collection";

export const debtIntroText01 = (
  <>
    We can help regardless of how small or large your debtor's book is. Our
    trained team can worry about the money you’ve already earned so that you can
    concentrate on more important matters like running your business.
  </>
);

export const debtStepsList = (
  <ol>
    <li>Pre-legal</li>
    <li>Call centre phone call</li>
    <li>Text/Email</li>
    <li>Section 129 letter</li>
    <li>Default listing on Credit Bureau</li>
    <li>Summons</li>
    <li>Judgement</li>
    <li>Warrant of execution</li>
  </ol>
);
