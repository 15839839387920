import React, { useState } from "react";
import { Button, ConfigProvider, Modal, Typography } from "antd";
import { MailOutlined, PhoneFilled } from "@ant-design/icons";
import animated from "../../../assets/AL_inc_animated.mp4";
import { configProvider_Home } from "../../../shared/utils/antConfigProvider";
import { launchText } from "../../../shared/utils/constants";

import "./Splash.scss";

function Splash() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { Title } = Typography;

  const colorPrimary = "#6a5acd";
  const modalContentStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 20px",
  };
  const modalStyles = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderTop: `1px solid ${colorPrimary}`,
    justifyContent: "space-between",
    padding: "20px",
    textAlign: "center",
  };
  const iconStyles = {
    fontSize: "48px",
    color: colorPrimary,
  };

  return (
    <ConfigProvider theme={configProvider_Home}>
      <div className="splash-background">
        <div className="splash-box-inner">
          <div className="temp-header">
            <Button onClick={handleOpen} type="primary">
              Contact
            </Button>
            <Modal
              footer={null}
              header={null}
              open={open}
              onCancel={handleClose}
            >
              <>
                <Title level={2}>Contact us</Title>
                <div style={modalStyles}>
                  <div style={modalContentStyles}>
                    <MailOutlined style={iconStyles} />
                    <Title level={5}>EMAIL</Title>
                    <Title level={5}>
                      <a href="mailto:alayna@lewendallaw.co.za">
                        alayna@lewendallaw.co.za
                      </a>
                      <br />
                      <a href="mailto:solutions@lewendallaw.co.za">
                        solutions@lewendallaw.co.za
                      </a>
                    </Title>
                  </div>
                  <div style={{ ...modalContentStyles, marginTop: "-23px" }}>
                    <PhoneFilled style={iconStyles} />
                    <Title level={5}>PHONE</Title>
                    <Title level={5}>
                      <a href="tel:+27 21 565 0473">021 565 0473</a>
                    </Title>
                  </div>
                </div>
              </>
            </Modal>
          </div>
          <div className="content">
            <video height="320px" width="auto" muted autoPlay loop>
              <source src={animated} />
            </video>

            <Title level={2} style={{ paddingTop: "20px", color: "#6a5acd" }}>
              {launchText}
            </Title>
            <div className="notify-me"></div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
}

export default Splash;
