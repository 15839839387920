import React from "react";

import "./AlLogo.scss";

import LogoPolygon from './LogoPolygon';

function AlLogo({ fill =  { default: '#6a5acd' }, outline }) {
  return (
    <div className="al-logo-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0  600 678.73"
        className="al-full-logo"
      >
        <LogoPolygon fill={fill}/>
      </svg>
    </div>
  );
}


export default AlLogo;
