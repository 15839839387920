import React, { useState, useEffect } from "react";
import "./TheHeader.scss";
import AlLogo from "shared/components/AlLogo/AlLogo";
import { ContactModal } from "shared/components";

function TheHeader({ colorTheme }) {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 880);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`header ${isMobileView ? 'mobile' : ''}`}>
      <AlLogo outline="white" />
      <p className="the-menu the-btns"><ContactModal /></p>
      
    </div>
  );
}

export default TheHeader;
