import React from "react";
import * as content from "./EstatePlanningContent";

export const introTitle = "What is estate planning?";
export const introText = (
  <>
    {content.estatePlanningText01}
    <br />
    {content.estatePlanningText02}
  </>
);

export const servicesTitle = "Our Services:";
export const servicesText = content.estatePlanningServices;

const introAndServicesContent = [
  {
    title: introTitle,
    text: introText,
    showTitle: false
  },
  {
    title: servicesTitle,
    text: servicesText,
    showTitle: true
  },
];

export default introAndServicesContent;
