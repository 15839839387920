import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  //About,
  ContactUs,
  Conveyencing,
  CorporateCommercial,
  DebtCollection,
  EstatePlanning,
  IntellectualProperty,
  Home,
  Labour,
  Services,
  //Splash,
  Team,
} from "modules/pages";
import "./TheContent.scss";
import { routeKeys } from "../../shared/utils/constants";

function TheContent() {
  return (
    <>
      <Routes>
        {/* <Route path={routeKeys.About} element={<About />} /> */}
        <Route path={routeKeys.Corporate} element={<CorporateCommercial />} />
        <Route path={routeKeys.Conveyancing} element={<Conveyencing />} />
        <Route path={routeKeys.DebtCollection} element={<DebtCollection />} />
        <Route path={routeKeys.Contact} element={<ContactUs />} />
        {/* <Route path={routeKeys.Team} element={<MeetTeam />} /> */}
        <Route path={routeKeys.Home || "*"} element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path={routeKeys.LabourEmployment} element={<Labour />} />
        <Route path={routeKeys.Team} element={<Team />} />
        <Route path={routeKeys.EstatePlanning} element={<EstatePlanning />} />
        <Route
          path={routeKeys.Intellectual}
          element={<IntellectualProperty />}
        />
        <Route path={routeKeys.Services} element={<Services />} />
        {/* <Route path={routeKeys.Splash} element={<Splash />} /> */}
      </Routes>
    </>
  );
}

export default TheContent;
