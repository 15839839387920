export const estatePlanningTitle = "Estate planning and Administration";
export const estatePlanningText01 = (
  <>
    Estate planning is the management and arrangement of assets during a
    person's life, in anticipation of death or incapacitation. Estate planning
    allows you to consider whether a will is required, the liquidity your estate
    may need, whether you are sufficiently covered and whether your business
    succession plan is practical given its current form.
  </>
);

export const estatePlanningText02 = (
  <>
    Estate planning is not something to fear or dread, it is an opportunity to
    set the rest of your life in motion and to live in the security and freedom
    that your family is secure and that your estate and wealth accrue during
    your lifespan.
  </>
);
export const estatePlanningText03 = (
  <>
    It is a misconception that estate planning is for the wealthy. There is no
    one size fits all approach to estate planning. Your bequests are as unique
    as your circumstance. We are equipped and can help you set the rest of your
    life in motion.
  </>
);

export const estatePlanningServices = (
  <>
    <span>Our Services:</span>
    <ul>
      <li>Assessment of your assets and liability portfolio.</li>
      <li>Business sustainability model and succession plan.</li>
      <li>Drafting will.</li>
      <li>Drafting living trusts.</li>
    </ul>
  </>
);
