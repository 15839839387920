export const titleText = "Corporate and Commercial ";
export const titleText01 = (
  <>Legal principles applicable to companies and other legal entities</>
);

export const introCoporateAndCommercial01 = (
  <>Our commercial department is geared to service all your corporate needs.</>
);
export const introCoporateAndCommercial01_1 = (
  <>
    Whether you’re starting up or want to improve your structures, systems, and
    policies to grow and succeed.
  </>
);

export const introCoporateAndCommercial02 = (
  <>
    We also appreciate the bureaucracy around compliance and the regulatory
    framework that governs the oil and gas, construction, and medical sector.
  </>
);
export const introCoporateAndCommercial02_1 = (
  <>We have serviced parastatals, non-profit organizations, and corporations.</>
);

export const ourServiceLeft = (
  <>
    <ul>
      <li>Business rescue and Insolvency proceedings.</li>
      <li>Commercial law disputes </li>
      <li>Draft Commercial Lease agreements </li>
      <li>Drafting business plans</li>
      <li>Due diligence </li>
      <li>
        Drafting contracts agreements like shareholding, joint venture
        agreements
      </li>
    </ul>
  </>
);
export const ourServiceRight = (
  <ul>
    <li>Training and advice on employment law and labour relation</li>
    <li>Negotiating and drafting contracts</li>
    <li>Internal governance audits</li>
    <li>Forensic investigation</li>
    <li>
      Registration of business entities (Companies, Trusts, Non-Profits,
      Co-operatives)
    </li>
    <li>Tender documentation</li>
  </ul>
);
