import React from "react";
import { Typography } from "antd";
import colors from "../../../app/Config.scss";

function TitleSmall({ children, color = "white", ...props }) {
  const { Title } = Typography;
  return (
    <Title level={5} style={{ color: colors.colorBrand_Ivory }} {...props}>
      {children}
    </Title>
  );
}

export default TitleSmall;
