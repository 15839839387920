import React from "react";
import { Card, Typography } from "antd";
import * as content from "./ValuesContent";
import "./Values.scss";
/*import {
  TitleSmall,
  TwoColumnContainer,
} from "../../../../../shared/components";*/
import colors from "../../../../../app/Config.scss";

function Values() {
  const { Title, Text } = Typography;
  return (
    <>
      <section className="values-section">
        <div className="our-values-title">
          <Title style={{ color: "" }}>Our Values</Title>
        </div>
        <div className="grid-values">
          {content.titleTextId.map((info) => (
            <div className="cards">
              <Card
                style={{
                  backgroundColor: "#342958",
                  border: `4px solid ${colors.colorBrand_Pink}`,
                }}
              >
                <>
                  <Title
                    level={4}
                    style={{ color: `${colors.colorBrand_Pink}` }}
                  >
                    {info.title}
                  </Title>
                  <Text style={{ color: "white" }}>{info.text}</Text>
                </>
              </Card>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default Values;
