export const labourTitle = "Labour and Employment Law";

export const labourText01 = (
  <>
    Given the complex and onerous labour law framework in South Africa, we
    understand that it is important to be kept updated with all
    employment-related matters.
  </>
);

export const labourText01_1 = (
  <>
    Our firm is committed to keeping you informed. Our team has dealt with an
    array of different Labour Law matters involving arbitrations, chairing
    disciplinary hearings, restructuring organisations; drafting employment
    contracts, reviewing and drafting policies and procedures and equipping
    staff with training.
  </>
);
export const labourText02 = (
  <>
    We have had the opportunity to work in both the public and private sectors
    and have extensive experience on a national level at the CCMA, Labour Court,
    and Bargaining Council Forums.
  </>
);
export const labourServices01 = (
  <ul>
    <li>
      CCMA, Bargaining Council, Labour Court, and Labour Appeal Court
      Litigation.
    </li>
    <li>Collective agreements.</li>
    <li>Drafting employment and consultant agreements.</li>
    <li>Restructuring and Retrenchment Processes.</li>
    <li>Training on Disciplinary Processes and Trial Advocacy .</li>
  </ul>
);
export const labourServices02 = (
  <ul>
    <li>
      Draft Policies; Employment Equity Policies, skills development policies,
      grievance policies and procedures, and other policies and procedures
      relevant to your business.
    </li>

    <li>Performance management.</li>
    <li>Provide human resource start-up packages for new companies.</li>
  </ul>
);
