import React from "react";

import "./TwoColumnContainer.scss";
import CarouselTextContent from "./CarouselTextContent";

function TwoColumnContainer({
  contentLeft,
  contentRight,
  styleLeft,
  styleRight,
  ...props
}) {
  return (
    <div className="the-two-columns" {...props}>

      <div className="colum-content left carousel-div" style={{ ...styleLeft }}>
        {contentLeft}
      </div>
      <div className="colum-content right carousel-div" style={{ ...styleRight }}>
        <CarouselTextContent content={contentRight}/>
      </div>
    </div>
  );
}

export default TwoColumnContainer;
