import { Card } from "antd";
import { Typography } from "antd";

function CarouselTextContent({ content }) {
  const Title = Typography;

  const showTitle = content.showTitle || false;

  return (
    <Card
      title={showTitle ? <Title>{content.title}</Title> : null}
      bordered={false}
    >
      {content.text}
    </Card>
  );
}

export default CarouselTextContent;
