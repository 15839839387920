import React from "react";
import { practiceAreas } from "./OurAreasContent";
import { Typography } from "antd";
import "./OurAreas.scss";

function OurAreas() {
  const { Title } = Typography;
  return (
    <section className="our-areas-section">
      <div className="our-areas-title">
        <Title>Our Areas</Title>
      </div>
      <div className="areas-grid">
        {practiceAreas.map((area) => (
          <p
            className="area-tile"
            key={area.key}
            style={{ backgroundColor: `${area.color}`}}
          >
            <Title className="title" level={5} style={{ color: `${area.font}`}}>
              {area.label}
            </Title>
          </p>
        ))}
      </div>
    </section>
  );
}

export default OurAreas;
