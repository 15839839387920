import React from "react";

function AlAfrica({
  fillSpace = "none",
  fillOutline = "#000",
  fillTriangles = "#443a8c",
  ...props
}) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 514.76 678.73"
      stroke={fillOutline}
      strokeMiterlimit={"10"}
      strokeWidth={"12px"}
      {...props}
    >
      <line fill={fillSpace} x1="171.62" y1="301.75" x2="256.88" y2="214.17" />
      <polyline
        fill={fillTriangles}
        points="426.82 220.67 257.89 216.12 267.59 276.68"
      />
      <line fill={fillSpace} x1="226.54" y1="375.26" x2="331.9" y2="382.67" />
      <line fill={fillSpace} x1="396.87" y1="532.2" x2="326.06" y2="400.49" />
      <line fill={fillSpace} x1="397.18" y1="537.81" x2="428.82" y2="467.19" />
      <line fill={fillSpace} x1="421.31" y1="220.67" x2="446.5" y2="306.5" />
      <line fill={fillSpace} x1="464.56" y1="388.58" x2="505.44" y2="299.97" />
      <line fill={fillSpace} x1="427.47" y1="471.03" x2="464.62" y2="388.58" />
      <polyline
        fill={fillTriangles}
        points="230.75 376.86 178.98 303.55 265.51 292.32"
      />
      <line x1="329.82" y1="382.32" x2="326.5" y2="404.5" />
      <polyline
        fill={fillTriangles}
        points="508.35 297.06 440.29 312.32 489.71 326.89"
      />
      <polyline
        fill={fillTriangles}
        points="349.53 431.96 426.45 435.68 386.38 501.54"
      />
      <polyline
        fill={fillTriangles}
        points="459.94 396.57 373.5 408.5 453.37 419.05"
      />
      <polyline
        fill={fillTriangles}
        points="272.42 296.22 331.73 377.71 426.05 246.33"
      />
      <polyline
        fill={fillTriangles}
        points="359 386.83 413.84 309.5 467.92 367.39"
      />
    </svg>
  );
}

export default AlAfrica;
