import React, { useState } from "react";
import { ConfigProvider, Modal } from "antd";
import { MailOutlined, PhoneFilled, WhatsAppOutlined } from "@ant-design/icons";
import { configProvider_Home } from "../../../shared/utils/antConfigProvider";

function ContactModal() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const colorPrimary = "#6a5acd";
  const modalContentStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 20px",
    fontSize: "0.8rem",
  };
  const modalStyle = {
    width: "100%",
  };
  const modalStyles = {
    display: "flex",
    flexDirection: "column", // Default to vertical layout
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "center",
    '@media (min-width: 768px)': { // Apply horizontal layout on screens wider than 768px
      flexDirection: "row",
    },
  };
  const iconStyles = {
    fontSize: "3rem",
    color: colorPrimary,
    padding: "20px 20px",
  };

  return (
    <ConfigProvider theme={configProvider_Home}>
      <style>
        {`
          @media (min-width: 768px) {
            .modal-styles {
              flex-direction: row;
            }
          }
        `}
      </style>
      <span onClick={handleOpen} type="primary">
        Contact us
      </span>
      <Modal footer={null} header={null} open={open} onCancel={handleClose} style={modalStyle}>
        <>
          <div className="modal-styles" style={modalStyles}>
            <div style={modalContentStyles}>
              <MailOutlined style={iconStyles} />
                <a href="mailto:hello@lewendallaw.co.za">
                  hello@lewendallaw.co.za
                </a>
            </div>
            <div style={{ ...modalContentStyles}}>
              <PhoneFilled style={iconStyles} />
                <a href="tel:+21 216 0011">021 216 0011</a>
            </div>
            <div style={{ ...modalContentStyles}}>
              <WhatsAppOutlined style={iconStyles} />
                <a href="https://api.whatsapp.com/send?phone=0658444347" >065 844 4347</a>
            </div>
          </div>
        </>
      </Modal>
    </ConfigProvider>
  );
}

export default ContactModal;
