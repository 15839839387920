import React from "react";
import { ConfigProvider } from "antd";
import { TheContent } from "../../containers";

function TheLayout() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Kalinga",
        },
      }}
    >
      <TheContent />
      
    </ConfigProvider>
  );
}

export default TheLayout;
