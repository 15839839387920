import React from 'react';
function LogoOuterPolygon({ className, fill, points }) {
    return (
      <polygon
        className={className}
        style={{ fill: fill }}
        points={points}
      />
    );
  }

export default LogoOuterPolygon;
