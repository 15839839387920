export const introTitle = "";
export const introText = (
  <>
    Our commercial department is geared to service all your corporate needs. Whether you’re starting up or want to improve your structures, systems, and
    policies to grow and succeed. We also appreciate the bureaucracy around compliance and the regulatory
    framework that governs the oil and gas, construction, and medical sector.
    We have serviced parastatals, non-profit organizations, and corporations.
  </>
);

export const servicesTitle = "Our services include:";
export const servicesLeft = (
  <>
    <ul>
      <li>Business rescue and Insolvency proceedings.</li>
      <li>Commercial law disputes </li>
      <li>Draft Commercial Lease agreements </li>
      <li>Drafting business plans</li>
      <li>Due diligence </li>
      <li>
        Drafting contracts agreements like shareholding, joint venture
        agreements
      </li>
    </ul>
  </>
);
export const servicesRight = (
  <ul>
    <li>Training and advice on employment law and labour relation</li>
    <li>Negotiating and drafting contracts</li>
    <li>Internal governance audits</li>
    <li>Forensic investigation</li>
    <li>
      Registration of business entities (Companies, Trusts, Non-Profits,
      Co-operatives)
    </li>
    <li>Tender documentation</li>
  </ul>
);

const content = [
  {
    title: introTitle,
    text: introText,
    showTitle: true
  },
  {
    title: servicesTitle,
    text: (
      <>
        {servicesLeft}
        {servicesRight}
      </>
    ),
    showTitle: true
  },
];

export default content;
