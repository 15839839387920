import React from "react";
import { ServicePage } from "shared/components";
import backgroundImage from "assets/Images/Services/conveyancing.jpg";
import * as content from "./ConveyancingContent";
import introAndServicesContent from "./IntroAndServicesContent";

function Conveyancing() {
  const pageContent = {
    background: backgroundImage,
    landingContent: {
      title: content.conveyancingTitle,
      text: content.conveyancingText01,
    },
    introAndServicesContent: introAndServicesContent,
  };
  
  return <ServicePage content={pageContent} />;
}

export default Conveyancing;
