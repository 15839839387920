//import color from "../../app/Config.scss";
import { ContactModal } from "../components";

export const routeKeys = {
  About: "/about",
  Contact: "/contact",
  Corporate: "/corp_commercial",
  Conveyancing: "/conveyancing",
  DebtCollection: "debt_collection",
  DisputeResolution: "/dispute_resolution",
  EstatePlanning: "/estate_planning",
  Home: "/home",
  LabourEmployment: "/labour_employment",
  Intellectual: "/intellectual_property",
  Splash: "/",
  Services: "/our_services",
  Team: "/meet_the_team",
};

export const launchText = "Launching Soon...";

export const menuOptions = [
  /*{ label: <a href={routeKeys.Home}>Home</a>, key: "home" },*/
  /*{
    label: <a href={routeKeys.Team}>Meet the Team</a>,
    key: "about",
    route: routeKeys.Team,
  },*/
  /*{
    label: "Services",
    key: "services",
    children: [
      {
        label: <a href={routeKeys.Corporate}>Corporate and Commercial</a>,
        key: "corpAndCommercial",
      },
      {
        label: (
          <a href={routeKeys.LabourEmployment}>Labour and Employment Law</a>
        ),
        key: "labor",
      },
      {
        label: (
          <a href={routeKeys.EstatePlanning}>
            Estate planning and Administration
          </a>
        ),
        key: "estate",
      },
      {
        label: <a href={routeKeys.DebtCollection}>Debt collection</a>,
        key: "debt",
      },
      {
        label: (
          <a href={routeKeys.Conveyancing}>Conveyancing and Property Law</a>
        ),
        key: "conveyProp",
      },
      {
        label: <a href={routeKeys.Intellectual}>Intellectual Property</a>,
        key: "intProp",
      },
    ],
  }*/,
  {
    label: <ContactModal />,
    key: "contact",
  },
];

export const systemPhrases = {
  standard: {
    ConfirmPassword: "Confirm password",
    CreatePassword: "Create a password",
    Email: "Email",
    Login: "Login",
    Password: "Password",
    SignUp: "Sign up",
  },
  validationMessages: {
    emailRequired: "An email is required",
    enterEmail: "Please enter your password",
    enterPassword: "Please Enter Password",
    fieldRequired: "This Field is Required",
    incorrectEmailPassword: "Incorrect email or password",
    invalidEmail: "Invalid Email",
    passwordRequired: "A password is required",
    passwordRule: "The password must contain 6 or more characters",
    passwordsNoMatch: "The passwords do not match",
  },
};
