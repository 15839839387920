import * as content from "./DebtCollectionContent";

export const introTitle = "Reliable and efficient debt collection services.";
export const introText = content.debtIntroText01;

export const servicesTitle = "Our debt collection process includes:";
export const servicesText = content.debtStepsList;

const introAndServicesContent = [
  {
    title: introTitle,
    text: introText,
    showTitle: false
  },
  {
    title: servicesTitle,
    text: servicesText,
    showTitle: true
  },
];

export default introAndServicesContent;
