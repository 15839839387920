import React from "react";
import { ServicePage } from "shared/components";
import backgroundImage from "assets/Images/Services/corporate_commercial.jpg";
import * as content from "./CorportateAndCommercialContent";
import introAndServicesContent from "./IntroAndServicesContent";

function CorporateCommercial() {
  const pageContent = {
    background: backgroundImage,
    landingContent: {
      title: content.titleText,
      text: content.titleText01,
    },
    introAndServicesContent: introAndServicesContent
  };
  return <ServicePage content={pageContent} />;
}

export default CorporateCommercial;
