export const introTitle = "Intellectual Property";
export const introText = (
  <>
    With the fast-moving pace of creativity and innovation in the global sphere,
    we have embarked on a sector of law that requires specificity and much focus
    when servicing client in IP Law. We understand that a client’s creative work
    means a lot to them and the protection thereof means a lot to us.
  </>
);

export const servicesTitle = "Our services include:";
export const servicesLeft = (
  <>
    <ul>
      <li>Researching a brand or Mark.</li>
      <li>
        Researching the Company/Business/Individual who may hold the same or a
        similar brand.
      </li>
      <li>Registration of a Trademark.</li>
    </ul>
  </>
);
export const servicesRight = (
  <ul>
    <li>Researching Patents.</li>
    <li>Litigious processes for infringed Intellectual Property Rights.</li>
    <li>Renewing of Trademarks registration.</li>
  </ul>
);

const content = [
  {
    title: introTitle,
    text: introText,
    showTitle: true
  },
  {
    title: servicesTitle,
    text: (
      <>
        {servicesLeft}
        {servicesRight}
      </>
    ),
    showTitle: true
  },
];

export default content;
