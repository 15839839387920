
import React from "react";
import * as content from "./ConveyancingContent";

export const introTitle = "Our conveyancing department is ready to service all your property law needs.";
export const introText = (
  <>
    {content.conveyancingText01}
    {content.conveyancingText02}
  </>
);

export const servicesTitle = "Our services include:";
export const servicesLeft = content.conveyancingServices01;
export const servicesRight = content.conveyancingServices02;

const introAndServicesContent = [
  {
    title: introTitle,
    text: introText,
    showTitle: false
  },
  {
    title: servicesTitle,
    text: (
      <>
        {servicesLeft}
        {servicesRight}
      </>
    ),
    showTitle: true
  },
];

export default introAndServicesContent;
