export const conveyancingTitle = "Conveyancing and Property Law ";

export const conveyancingText01 = (
  <>
    With over 20 years of combined experience in the property sector, our team
    of property professionals offer expert legal advice and guidance on all
    aspects of Property law.
  </>
);
export const conveyancingText02 = (
  <>
    With a wealth of knowledge and understanding of the property market and the
    ancillary issues that affect landowners, developers, occupiers as well as
    investors, our team is well versed to meet your every need.
  </>
);

export const ourServices = <>Our Services:</>;

export const conveyancingServices01 = (
  <>
    <ul>
      <li>Property Valuation.</li>
      <li>Buying.</li>
      <li>Selling.</li>
      <li>Renting.</li>
      <li>Bond approvals.</li>
      <li>After Sales Service.</li>
      <li>Lease Agreements.</li>
      <li>Sale / Offer Agreements.</li>
    </ul>
  </>
);
export const conveyancingServices02 = (
  <>
    <ul>
      <li>Evictions: Landlords and Tenats</li>
      <li>Contracts.</li>
      <li>Conveyancing and Property Transfers.</li>
      <li>Deceased estate properties.</li>
      <li>Property development.</li>
      <li>Managing Property portfolios.</li>
      <li>Lease Agreements.</li>
      <li>Sale / Offer Agreements.</li>
    </ul>
  </>
);
