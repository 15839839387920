import color from "../../../../../app/Config.scss";
import { routeKeys } from "../../../../../shared/utils/constants";
export const practiceAreas = [
  {
    label: "Corporate and Commercial",
    route: routeKeys.Corporate,
    key: "corpAndCommercial",
    color: color.colorBrand_Gold,
    font: color.colorBrand
  },
  {
    label: "Labour and Employment Law",
    route: routeKeys.LabourEmployment,
    key: "labor",
    color: color.colorBrand_Green,
  },
  {
    label: "Estate planning and Administration",
    route: routeKeys.EstatePlanning,
    key: "estate",
    color: color.colorBrand_Pink,
  },
  {
    label: "Debt Collection",
    route: routeKeys.DebtCollection,
    key: "debt",
    color: color.colorBrand,
  },
  {
    label: "Conveyancing and Property Law",
    route: routeKeys.Conveyancing,
    key: "conveyancing",
    color: color.colorBrand_Blue,
  },
  {
    label: "Intellectual Property",
    route: routeKeys.Intellectual,
    key: "intProp",
    color: color.colorBrand_Red,
  },
  {
    label: "Family Law",
    route: routeKeys.Intellectual,
    key: "intProp",
    color: color.colorBase,
    font: color.colorBrand
  }
];
