import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React, { useState } from "react";
import { Card, Modal } from "antd";

const { Meta } = Card;

const TeamMember = ({ content }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleCardClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

 
  return (
    <>
      <Card 
        className="team-member-card" // Update the class name to match your SCSS
        hoverable onClick={handleCardClick} cover={<img alt={content.name} src={require(`assets/Images/staff/${content.photo}`)} />}>
        <Meta title={''} description={(
            <>
              {content.name}
              <hr />
              {content.jobTitle}
            </>
          )} />
      </Card>
      <Modal
        open={modalOpen}
        title={content.name}
        onCancel={handleModalClose}
        footer={null}
      >
        <p>{content.details}</p>
        <p>Email: <a href={`mailto:${content.email}`}>{content.email}</a><CopyOutlined
                style={{ marginLeft: '5px', cursor: 'pointer' }}
                onClick={() => {
                    navigator.clipboard.writeText(content.email);
                    message.success('Email copied to clipboard');
                }}
                /></p>
        
        <p>Phone: <a href={`tel:${content.phoneNumber}`}>{content.phoneNumber}</a><CopyOutlined
            style={{ marginLeft: '5px', cursor: 'pointer' }}
            onClick={() => {
                navigator.clipboard.writeText(content.phoneNumber);
                message.success('Phone number copied to clipboard');
            }}
            /></p>
      </Modal>
    </>
  );
};

export default TeamMember;
