export const intellectualTitle = "Intellectual Property";

export const intellectualText01 = (
  <>
    With the fast-moving pace of creativity and innovation in the global sphere,
    we have embarked on a sector of law that requires specificity and much focus
    when servicing client in IP Law. We understand that a client’s creative work
    means a lot to them and the protection thereof means a lot to us.
  </>
);

export const intellectualText02 = (
  <>
    Registering a brand/mark can become quite intricate to lay-person, however
    our trained team exist to assist businesses, creatives and innovators in
    protecting their brand/mark. Our Intellectual Property department does an
    in- depth research into current brands and marks that may potentially be the
    same or similar to the clients brand/mark and advise the client accordingly
    regarding the outcome of this research.
  </>
);
export const intellectualText03 = (
  <>
    The IP department further registers the brand/mark the client wishes to
    protect and liaise with the prescribed body to ensure all conditions are met
    to finalise the registration. The IP department further attends to Trademark
    and Copyright Infringements to ensure the rights of the rights holder are
    not infringed and should this require litigation, we service our clients in
    this too.
  </>
);
<span>Our Services:</span>;
export const ourServicesText01 = (
  <ul>
    <li>Researching a brand or Mark.</li>
    <li>
      Researching the Company/Business/Individual who may hold the same or a
      similar brand.
    </li>
    <li>Registration of a Trademark.</li>
  </ul>
);
export const ourServicesText02 = (
  <ul>
    <li>Researching Patents.</li>
    <li>Litigious processes for infringed Intellectual Property Rights.</li>
    <li>Renewing of Trademarks registration.</li>
  </ul>
);
